import { DefaultValue, atom, selector } from "recoil";
import { getStakeApi } from "../_utils/stakeApi";
import { userPubkeyPromise } from "../_utils/userPubkey";
import { PublicKey } from "@solana/web3.js";
import { currentMadLadAtom } from "./currentMadladAtom";
import finalGoldPerLadSnapshot from "../_participants/finalGoldPerLadSnapshot.json"
import finalLadPerWalletSnapshot from "../_participants/finalLadPerWalletSnapshot.json"
import { pubkeyAtom } from "./userNftsAtom";

export const goldBalanceAtom = selector<number | null>({
  key: "goldBalanceAtom",
  get: async ({get})=>{
    const publicKey = get(pubkeyAtom);
    const currentMadLad = get(currentMadLadAtom);

    const mint = currentMadLad.account.mint;

    const lad = finalLadPerWalletSnapshot.find((lad) => lad.mint === mint && lad.owner === publicKey);

    if(!lad) return null;
    const goldPerLad = finalGoldPerLadSnapshot[lad.mint]?.totalDust ?? null;
    
    return goldPerLad;
  },
});

// export const goldBalanceAtom = atom<number | null>({
//   key: "goldBalanceAtom",
//   default: null,
//   effects: [
//     ({ setSelf, getPromise }) => {
//       let interval;
//       let cancelled = false;
//       Promise.all([
//         userPubkeyPromise,
//         getStakeApi(),
//         getPromise(currentMadLadAtom)
//       ]).then(([publicKey, stakeApi, currentMadLad]) => {
//         const userPubkey = new PublicKey(publicKey)
//         const nft = {
//           mintAddress: new PublicKey(currentMadLad.account.mint),
//           metadataAddress: new PublicKey(currentMadLad.publicKey),
//         };
//         let gold: number | null = null
//         let isStaked = false;

//         // Optional: Invoke this to warm the cache.
//         stakeApi.isSoulBoundAuthorityUserInitialized(userPubkey);
//         const refreshGold = async () => {
//           if (cancelled) {
//             clearInterval(interval);
//             return
//           }
      
//           const timeModulo = Math.floor(Date.now() / 1000) % 10;
//           try {
//             if (timeModulo === 0 || gold === null) {
//               isStaked = await stakeApi.isStaked({
//                 user: userPubkey,
//                 nft,
//               });
//               if(isStaked || gold === null) {
//                 gold = await stakeApi.readGoldPoints({
//                   user: userPubkey,
//                   nft,
//                 });
  
//                 setSelf(gold);
//               }
//             }
//             else if(isStaked) {
//               setSelf((gold) => {
//                 if (gold instanceof DefaultValue) {
//                   return null;
//                 }
//                 gold++
//                 return gold;
//               })
//             }
//           }
//           catch (e) {
//             setSelf((gold) => {
//               if (gold instanceof DefaultValue || gold === null) {
//                 return gold;
//               }
//               gold++;
//               return gold
//             })
//           }
//         };
//         if(!cancelled) {
//           interval = setInterval(refreshGold, 1000);
//         }
//       });


//       return () => {
//         cancelled = true;
//         clearInterval(interval);
//       };
//     }
//   ]
// })

